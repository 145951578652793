import React from "react";
import "../../sass/typography.scss";
import { TextDisplayProps } from "./Text.type";
import { colors } from "../../theme/colors";

const Text: React.FC<TextDisplayProps> = ({
  text,
  styleName,
  color = colors.black,
  onClick = () => {}
}): JSX.Element => {
  return (
    <div className={`${styleName}`} style={{ color }} onClick={onClick}>
      {text}
    </div>
  );
};

export default Text;
