import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '../page/Login/authSlice';

const baseUrl = process.env.REACT_APP_REST_BASE_URL;


export const authorizedBaseQuery = () => {

    if (!baseUrl) {
        throw new Error("Base URL is not defined");
    }

    return fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
			const state: AuthState =  getState() as AuthState;
			headers.set('Authorization', state?.auth?.token ? `Bearer ${state?.auth?.token}` : '');
			return headers;
		}
    });
}