import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";
import { InsightImpactAnalysisType } from "./InsightAnalysis.type";

export const InsightAnalysisQuery = createApi({
  reducerPath: "InsightAnalysisQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getInsightAnalysis: builder.query({
      query: () => ({
        url: `/insights`,
        method: "GET",
      }),
      transformResponse: (
        response: InsightImpactAnalysisType[]
      ): { insightAnalysisData: InsightImpactAnalysisType[] | undefined } => {
        const insightAnalysisData: InsightImpactAnalysisType[] = [];
        response.forEach((item) => {
          insightAnalysisData.push({
            id: item.id,
            promoted: item.promoted,
            title: item.title,
            status: item.status,
            frictionDescription: item.frictionDescription,
            description: item.description,
            date: item.date,
          });
        });
        return {
          insightAnalysisData,
        };
      },
    }),
  }),
});

export const { useGetInsightAnalysisQuery } = InsightAnalysisQuery;
