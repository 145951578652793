import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";

export const Users = createApi({
  reducerPath: "User",
  baseQuery: authorizedBaseQuery(),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUserForTenant: builder.query({
      query: (tenantId) => ({
        url: `/admin/users/tenants/${tenantId}`,
        method: "GET",
        providesTags: ['Users']
      }),
      transformResponse: (response: any[]) => response?.map(customer => ({
        ...customer,
        id: customer.username
      })),
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: `/admin/users/tenants/${user.tenantId}`,
        method: "POST",
        body: user,
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ['Users']
    }),
    updateUser: builder.mutation({
      query: (user) => ({
          url: `/admin/users/tenants/${user.tenantId}/${user.username}`,
          method: 'PUT',
          body: user
      }),
      invalidatesTags: ['Users']
  }),
  deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users']
  }),
  changeUserPassword: builder.mutation({
      query: ({username, password, tenantId}) => ({
        url: `/admin/users/tenants/${tenantId}/${username}/password`,
        method: 'PUT',
        body: {
          username: username,
          newPassword: password
        }
      }),
    })
  })
});

export const {
  useGetUserForTenantQuery, useAddUserMutation, useUpdateUserMutation, useDeleteUserMutation, useChangeUserPasswordMutation
} = Users