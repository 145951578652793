import React from "react";
import { Provider } from "react-redux";

import AppRoute from "./AppRoute";
import { store } from "./store";
import 'typeface-roboto';


const App = () => {
  return (
    <Provider store={store}>
      <AppRoute />
    </Provider>
  );
};

export default App;
