import React from 'react';
import '../../styles/feature/Layout/Layout.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface LayoutProps {
  children: any
}
const Layout: React.FC<LayoutProps> = ({children}) => {
  const { bgColor } = useSelector((state: RootState) => state.layout);
  return (
    <div className='layout-container' style={{backgroundColor: bgColor}}>
      {children}
    </div>
  )
}

export default Layout