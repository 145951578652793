export interface ProjectInsightQueryResponse {
  projectScoringData: ProjectScoringType[];
  projectEstimationData: ProjectEstimationType[];
  response: ProjectInsightsResponseType[];
}

export enum InsightTimeFrameEnum {
  ZERO_TO_THREE_MONTHS = "ZERO_TO_THREE_MONTHS",
  FOUR_TO_SIX_MONTHS = "FOUR_TO_SIX_MONTHS",
  SEVEN_TO_TWELVE_MONTHS = "SEVEN_TO_TWELVE_MONTHS",
  GREATER_THAN_TWELVE_MONTHS = "GREATER_THAN_TWELVE_MONTHS",
}

export const InsightTimeFrameEnumValue = {
  [InsightTimeFrameEnum.ZERO_TO_THREE_MONTHS]: "0 - 3 Months",
  [InsightTimeFrameEnum.FOUR_TO_SIX_MONTHS]: "4 - 6 Months",
  [InsightTimeFrameEnum.SEVEN_TO_TWELVE_MONTHS]: "7 - 12 Months",
  [InsightTimeFrameEnum.GREATER_THAN_TWELVE_MONTHS]: "12+ Months",
};

export type InsightTimeFrameKeys = keyof InsightTimeFrameEnum;

export const InsightStatusEnum = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  DELAYED: "DELAYED",
  CANCELLED: "CANCELLED",
} as const;

export type InsightStatusEnumType =
  (typeof InsightStatusEnum)[keyof typeof InsightStatusEnum];

export interface ProjectScoringType {
  id: string;
  importance: number;
  customersImpacted: number;
  severity: number;
  frequency: number;
  customerCentricScore: number;
  businessValue: number;
  implementationEase: number;
  strategicAlignment: number;
  orgCentricScore: number;
  overallScore: number;
  promoted: boolean;
  title: string;
}

export interface ProjectEstimationType {
  title: string;
  customerCentricScore: number;
  orgCentricScore: number;
  overallScore: number;
  date: string;
  promoted: boolean;
  [key: string]: boolean | string | number;
}

export interface ProjectInsightsResponseType extends ProjectScoringType {
  tenantId: string;
  date: string;
  description: string;
  frictionDescription: string;
  status: InsightStatusEnumType;
  newlyDiscovered: boolean;
  insightImplementationTimeFrame: InsightTimeFrameEnum;
}
