import React from 'react';
import '../../styles/feature/Header/UserSection.scss'
import { literals } from '../../theme/literals';

interface UserSectionProps {
  username: string;
  customClass?: string
  profilePic ?: string
  onLogout: () => any
} 
const UserSection: React.FC<UserSectionProps> = ({
  username,
  customClass = '',
  profilePic = 'https://picsum.photos/28',
  onLogout
}) => {
  return(
    <li className={`user-section-in-header ${customClass}`}>
      <div className="dropdown-section">
        <div className="dropbtn">
          <span className='username'>{username}</span>
          <img src={profilePic} alt='profile_pic' className='profile-pic' />
          </div>
        <div className="dropdown-content">
          <div className='item' onClick={onLogout}>{literals.logout}</div>
        </div>
      </div>
    </li>
  )
}

export default UserSection