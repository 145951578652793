import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const ContentEffectivenessCrudQuery = createApi({
  reducerPath: "ContentEffectivenessCrudQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getContentEffectiveness: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/content-csat/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response,
    }),
    addContentEffectiveness: builder.mutation({
      query: (data) => ({
        url: `/admin/content-csat`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),
    updateContentEffectiveness: builder.mutation({
      query: (data) => ({
        url: `/admin/content-csat/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    removeContentEffectiveness: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/content-csat/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetContentEffectivenessQuery,
  useAddContentEffectivenessMutation,
  useUpdateContentEffectivenessMutation,
  useRemoveContentEffectivenessMutation
} = ContentEffectivenessCrudQuery;