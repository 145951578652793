import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../feature/Header/Header";
import { getRoutes } from "./constants";
import { IRoute } from "./model";
import { Suspense } from "react";
import Loader from "../component/Loader/Loader";
import Footer from "../feature/Footer/Footer";
import '../styles/AppRoute/AppRoute.scss';
import Layout from "../feature/Layout/Layout";
import { Toaster } from 'react-hot-toast';

function AppRoute() {
  return (
    <div className="app-container">
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 7000,
          style: { fontFamily: 'Roboto'}
        }}
      />
      <Router>
        <Suspense fallback={<Loader isLoading={true} />}>
          <Header />
          <Layout>
            <Routes>
              {getRoutes?.map((route: IRoute) => (
                <Route path={route.path} element={route.element} key={route.path} />
              ))}
            </Routes>
          </Layout>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default AppRoute;
