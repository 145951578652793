import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const TenantCustomers = createApi({
  reducerPath: "TenantCustomers",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getTenantCustomers: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/customers/tenants/${tenantId}`,
        method: "GET",
      }),
    }),
    addTenantCustomers: builder.mutation({
      query: (customerData) => ({
        url: `/admin/customers`,
        method: "POST",
        body: customerData,
      }),
    }),
    updateTenantCustomers: builder.mutation({
      query: (customerData) => ({
        url: `/admin/customers`,
        method: "PUT",
        body: customerData,
      }),
    }),
    removeTenantCustomers: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/customers/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTenantCustomersQuery,
  useAddTenantCustomersMutation,
  useUpdateTenantCustomersMutation,
  useRemoveTenantCustomersMutation
} = TenantCustomers;
