import { GraphQLClient } from 'graphql-request';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const baseUrl = process.env.REACT_APP_GRAPHQL_BASE_URL;

if (!baseUrl) {
  throw new Error("Base URL is not defined");
}

type GraphQLRequest = {
  body: string;
};

export const graphqlBaseQuery: BaseQueryFn<GraphQLRequest, unknown, unknown> = async (
  args,
  api
) => {
  const { body } = args;
  const state = api.getState() as RootState;
  const token = state.auth.token;

  const client = new GraphQLClient(baseUrl);
  client.setHeaders({
    Authorization: token ? `Bearer ${token}` : ''
  });

  try {
    const response = await client.request(body);
    return { data: response };
  } catch (error: any) {
    return { error: error.message ? error.message : error.toString() };
  }
};
