export function filterTopicBySearchString(
  availbleTopics: string[],
  searchString: string
) {
  if (searchString.trim())
    return availbleTopics.filter((element) => element.includes(searchString));
  else return availbleTopics;
}

export const getUniqueDetractorTopic = (data: any): string[] => {
  const uniqueTopics = new Set();
  data.forEach((item: any) => {
    if (Array.isArray(item.detractorTopics)) {
      item.detractorTopics.forEach((topic: string) => uniqueTopics.add(topic));
    }
  });
  return Array.from(uniqueTopics) as string[];
};

export const checkTopicExistance = (
  existedTopics: string[],
  newTopic: string
) => {
  return existedTopics.includes(newTopic);
};
