import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const InsightsDetractorQuery = createApi({
  reducerPath: "InsightsDetractorQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getInsightsDetractor: builder.query({
      query: ({ tenantId, year, month }) => ({
        url: `/admin/detractor-words/tenants/${tenantId}?year=${year}&month=${month}`,
        method: "GET",
      }),
    }),
    addInsightsDetractor: builder.mutation({
      query: (insightsDetractorData) => ({
        url: `/admin/detractor-words/tenants/${insightsDetractorData.tenantId}`,
        method: "POST",
        body: getInsightsDetractorBody(insightsDetractorData),
        responseHandler: (response) => response.text(),
      }),
    }),
    updateInsightsDetractor: builder.mutation({
      query: (insightsDetractorData) => ({
        url: `/admin/detractor-words/tenants/${insightsDetractorData.tenantId}/${insightsDetractorData.id}`,
        method: "PUT",
        body: getInsightsDetractorBody(insightsDetractorData),
      }),
    }),
    removeInsightsDetractor: builder.mutation({
      query: ({ id, tenantId }) => ({
        url: `/admin/detractor-words/tenants/${tenantId}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetInsightsDetractorQuery,
  useAddInsightsDetractorMutation,
  useUpdateInsightsDetractorMutation,
  useRemoveInsightsDetractorMutation,
} = InsightsDetractorQuery;

const getInsightsDetractorBody = (insightsDetractorData: any) => {
  delete insightsDetractorData.tenantId;
  return {
    ...insightsDetractorData,
  }
}