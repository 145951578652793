import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CustomerData {
  data: any[];
}

const initialState: CustomerData = { data : [] };

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<any[] | null>) => {
      state.data = action.payload ?? [];
    },
    clearCustomerData: (state) => {
      state.data = [];
    },
  },
});

export const { setCustomerData, clearCustomerData } = customerSlice.actions;

export default customerSlice.reducer;