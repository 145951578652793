import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";


const baseUrl = process.env.REACT_APP_REST_BASE_URL;


export const Tenant = createApi({
  reducerPath: "tenant",
  baseQuery: authorizedBaseQuery(),
  tagTypes: ['Tenants'],
  endpoints: (builder) => ({
    getAlltenants: builder.query({
      query: () => ({
        url: "/admin/tenants",
        method: "GET",
        providesTags: ['Tenants']
      }),
    }),
    addTenants: builder.mutation({
      query: (tenant) => ({
        url: "/admin/tenants",
        method: "POST",
        body: tenant,
        responseHandler: (response) => response.text(),
      }),
      
      invalidatesTags: ['Tenants']
    }),
    updateTenant: builder.mutation({
      query: (tenant) => ({
          url: `/admin/tenants/${tenant.id}`,
          method: 'PUT',
          body: tenant
      }),
      
      invalidatesTags: ['Tenants']
  }),
  deleteTenant: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/tenants/${id}`,
          method: 'DELETE',
      }),
      invalidatesTags: ['Tenants']
  }),
  })
});

export const {
  useGetAlltenantsQuery, useAddTenantsMutation, useUpdateTenantMutation, useDeleteTenantMutation
} = Tenant