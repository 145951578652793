import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const token = useSelector((state: RootState) => state.auth.token);

  return token ? <>{element}</> : <Navigate to="/" />;
};

export default ProtectedRoute;
