import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const CustomerSegmentCrudQuery = createApi({
  reducerPath: "CustomerSegmentCrudQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getSegment: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/segments/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response,
    }),
    createSegment: builder.mutation({
      query: ({tenantId, payload}) => ({
        url: `/admin/segments/tenants/${tenantId}`,
        method: "POST",
        body: payload,
        responseHandler: (response) => response.text(),
      }),
    }),
    addCustomerToSegment: builder.mutation({
      query: ({tenantId, segmentId, customerId}) => ({
        url: `/admin/segments/tenants/${tenantId}/${segmentId}/add`,
        method: "PUT",
        body: { customerId },
        responseHandler: (response) => response.text(),
      }),
    }),
    addCustomerToMultipleSegment: builder.mutation({
      query: ({tenantId, segmentIds, customerId}) => ({
        url: `/admin/segments/tenants/${tenantId}/customers/${customerId}`,
        method: "PUT",
        body: { segmentIds },
        responseHandler: (response) => response.text(),
      }),
    }),
    removeCustomerFromSegment: builder.mutation({
      query: ({tenantId, segmentId, customerId}) => ({
        url: `/admin/segments/tenants/${tenantId}/${segmentId}/remove`,
        method: "PUT",
        body: { customerId },
      }),
    }),
    deleteSegment: builder.mutation({
      query: ({ segmentId, tenantId }) => ({
        url: `/admin/segments/tenants/${tenantId}/${segmentId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSegmentQuery,
  useLazyGetSegmentQuery,
  useAddCustomerToSegmentMutation,
  useAddCustomerToMultipleSegmentMutation,
  useCreateSegmentMutation,
  useDeleteSegmentMutation,
  useRemoveCustomerFromSegmentMutation
} = CustomerSegmentCrudQuery;