import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_REST_BASE_URL;
const base64Encode = (str: string) => btoa(str);

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getToken: builder.mutation<string, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: '/auth/token',
        method: 'POST',
        headers: {
          'Authorization': `Basic ${base64Encode(`${username}:${password}`)}`,
        },
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useGetTokenMutation } = authApi;
export default authApi;
