import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlBaseQuery } from '../../api/graphqlBaseQuery';

export const CustomerSummaryQuery = createApi({
  reducerPath: 'CustomerSummaryQuery',
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getCustomerSummary: builder.query<any, void>({
      query: () => ({
        body: gql`
          query CustomerSummary {
            customerSummary {
              touchpoints
              summary {
                customer
                priority
                health
                healthTrend
                nps
                npsTrend
                touchPointScores {
                  touchpoint
                  score
                  trend
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response,
    }),
  }),
});

export const {
  useGetCustomerSummaryQuery
} = CustomerSummaryQuery;
