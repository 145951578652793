import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const CHIQuery = createApi({
  reducerPath: "CHIQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCHI: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/customer-health/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response.map(
        item => ({
          ...item,
          customer: {
            name: item.customer,
            id: item.customerId
          }
        })
      ),
    }),
    addCHI: builder.mutation({
      query: (npsData) =>{
        return {
          url: `/admin/customer-health`,
          method: "POST",
          body: getBody(npsData),
        };
      },
    }),
    updateCHI: builder.mutation({
      query: (npsData) => ({
        url: `/admin/customer-health`,
        method: "PUT",
        body: getBody(npsData),
      }),
    }),
    removeCHI: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/customer-health/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCHIQuery,
  useAddCHIMutation,
  useUpdateCHIMutation,
  useRemoveCHIMutation
} = CHIQuery;


const getBody = (npsData: any) => {
  const body = {
    ...npsData,
    customerId: npsData.customer.id,
    score: parseFloat(npsData.score),
    customer: npsData.customer.name
  };
  return body;
}