import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";

export const headerApi = createApi({
  reducerPath: "headerApi",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getConfiguration: builder.query<any, void>({
      query: () => ({
        body: gql`
          query Configuration {
            configuration {
              logo
              theme
              signals
              insights
              projects
              values
            }
          }
        `,
      }),
      transformResponse: (response: any) => response,
    }),
  }),
});

export const { useGetConfigurationQuery, useLazyGetConfigurationQuery } = headerApi;
export default headerApi;
