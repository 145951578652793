import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { key } from "../../constants/storageKeys";

export interface AuthState {
  token: string | null;
  loginInfo: LoginInfo;
}

export interface LoginInfo {
  exp: number;
  firstName: string;
  iat: number;
  iss: string;
  lastName: string;
  scope: string;
  sub: string;
}

const initialState: AuthState = {
  token: sessionStorage.getItem("token"),
  loginInfo: JSON.parse(localStorage.getItem(key.loginInfo) || JSON.stringify({
    exp: 0,
    firstName: "",
    iat: 0,
    iss: "",
    lastName: "",
    scope: "",
    sub: ""
  }))
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      sessionStorage.setItem("token", action.payload);
    },
    clearToken: (state) => {
      state.token = null;
      sessionStorage.removeItem("token");
    },
    setLoginInfo: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.loginInfo = { ...state.loginInfo, ...action.payload };
    }
  },
});

export const { setToken, clearToken, setLoginInfo } = authSlice.actions;
export default authSlice.reducer;
