import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InsightQueryData {
  data: any;
  uniqueTopic: string[];
}
const initialState: InsightQueryData = { data: [], uniqueTopic: [] };
const insightQuerySlice = createSlice({
  name: "insightQuery",
  initialState,
  reducers: {
    setInsightQueryData: (
      state,
      action: PayloadAction<{
        data: any;
        uniqueTopic: string[];
      } | null>
    ) => {
      return action.payload ?? initialState;
    },
    clearInsightQueryData: () => {
      return initialState;
    },
  },
});

export const { setInsightQueryData, clearInsightQueryData } =
  insightQuerySlice.actions;

export default insightQuerySlice.reducer;
