import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";

export const SignalsRestQuery = createApi({
  reducerPath: "SignalsRestQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCustomerVisits: builder.query({
      query: ({ duration }) => ({
        url: `/signal-metrics/customer-visit?duration=${duration}`,
        method: "GET",
      }),
    }),
    getContentCSATGraph: builder.query({
      query: () => ({
        url: `/signal-metrics/content-csat`,
        method: "GET",
      }),
    }),
    getInterceptResponseRateGraph: builder.query({
      query: () => ({
        url: `/signal-metrics/intercept-response-rate`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCustomerVisitsQuery,
  useGetContentCSATGraphQuery,
  useGetInterceptResponseRateGraphQuery
} = SignalsRestQuery;