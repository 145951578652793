import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";
import {
  ProjectEstimationType,
  ProjectInsightQueryResponse,
  ProjectInsightsResponseType,
  ProjectScoringType,
} from "./ProjectInsights.type";
import { getTimeFrameCheckValue } from "./utils/ProjectEstimationTableGrid";

export const ProjectInsightsQuery = createApi({
  reducerPath: "ProjectInsightsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getProjectInsights: builder.query({
      query: () => ({
        url: `/insights`,
        method: "GET",
      }),
      transformResponse: (
        response: ProjectInsightsResponseType[]
      ): ProjectInsightQueryResponse => {
        const projectScoringData: ProjectScoringType[] = [];
        const projectEstimationData: ProjectEstimationType[] = [];
        response.forEach((item: ProjectInsightsResponseType) => {
          projectScoringData.push({
            id: item.id,
            importance: item.importance,
            customersImpacted: item.customersImpacted,
            severity: item.severity,
            frequency: item.frequency,
            customerCentricScore: item.customerCentricScore,
            businessValue: item.businessValue,
            implementationEase: item.implementationEase,
            strategicAlignment: item.strategicAlignment,
            orgCentricScore: item.orgCentricScore,
            overallScore: item.overallScore,
            promoted: item.promoted,
            title: item.title,
          });

          projectEstimationData.push({
            title: item.title,
            customerCentricScore: item.customerCentricScore,
            orgCentricScore: item.orgCentricScore,
            overallScore: item.overallScore,
            id: item.id,
            date: item.date,
            promoted: item.promoted,
            ...getTimeFrameCheckValue(item.insightImplementationTimeFrame),
          });
        });
        return {
          projectScoringData,
          projectEstimationData,
          response,
        };
      },
    }),
  }),
});

export const { useGetProjectInsightsQuery } = ProjectInsightsQuery;
