import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const TenantNpsQuery = createApi({
  reducerPath: "TenantNpsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getNPS: builder.query({
      query: ({ tenantId, atTenantLevel=false }) => ({
        url: `/admin/nps/tenants/${tenantId}` + (atTenantLevel ? '?atTenantLevel=true' : ''),
        method: "GET",
      }),
      transformResponse: (response: any[]) => response.map(
        item => ({
          ...item,
          customer: item.customer ? {
            name: item.customer,
            id: item.customerId
          }: null
        })
      ),
    }),
    addNPS: builder.mutation({
      query: (npsData) =>{
        return {
          url: `/admin/nps`,
          method: "POST",
          body: getNpsBody(npsData),
        };
      },
    }),
    updateNPS: builder.mutation({
      query: (npsData) => ({
        url: `/admin/nps`,
        method: "PUT",
        body: getNpsBody(npsData),
      }),
    }),
    removeNPS: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/nps/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetNPSQuery,
  useAddNPSMutation,
  useUpdateNPSMutation,
  useRemoveNPSMutation
} = TenantNpsQuery;


const getNpsBody = (npsData: any) => {
  const body = {
    ...npsData,
    customerId: npsData?.customer?.id ?? null,
    score: parseFloat(npsData.score),
    customer: npsData?.customer?.name ?? null
  };
  if(!body.customerId){
    delete body.customer;
    delete body.customerId;
  }
  return body;
}