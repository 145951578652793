import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const TenantCsatQuery = createApi({
  reducerPath: "TenantCsatQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCSAT: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/csat/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response.map(
        item => ({
          ...item,
          customer: {
            name: item.customer,
            id: item.customerId
          }
        })
      ),
    }),
    addCSAT: builder.mutation({
      query: (csatData) =>{
        return {
          url: `/admin/csat`,
          method: "POST",
          body: getCsatBody(csatData),
        };
      },
    }),
    updateCSAT: builder.mutation({
      query: (csatData) => ({
        url: `/admin/csat/${csatData.id}`,
        method: "PUT",
        body: getCsatBody(csatData),
      }),
    }),
    removeCSAT: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/csat/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCSATQuery,
  useAddCSATMutation,
  useUpdateCSATMutation,
  useRemoveCSATMutation
} = TenantCsatQuery;


const getCsatBody = (csatData: any) => {
  const body = {
    ...csatData,
    customerId: csatData.customer.id,
    score: parseFloat(csatData.score),
    customer: csatData.customer.name
  };
  return body;
}