import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";

export const Admin = createApi({
  reducerPath: "admin",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: ({ tenantId, formData }) => ({
        url: `/${tenantId}/upload`,
        method: "POST",
        body: formData,
      }),
    }),
    getErrors: builder.query({
      query: ({ id, fileType, requestId }) => ({
        url: `/documents/errors/tenants/${id}/request/${requestId}`,
        params: { fileType },
        method: "GET",
      }),
    }),
    getTenants: builder.query({
      query: () => ({
        url: `/documents/recent/tenants/all`,
        method: "GET",
      }),
    }),
    getFileUploadStatus: builder.query({
      query: ({ tenantId }) => ({
        url: `/documents/tenants/${tenantId}/all`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUploadFileMutation,
  useGetTenantsQuery,
  useGetErrorsQuery,
  useLazyGetFileUploadStatusQuery,
} = Admin;
