import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConfigState {
  logo: string | null;
  theme: string | null;
  signalsLocked: boolean;
  insightsLocked: boolean;
  projectsLocked: boolean;
  valuesLocked: boolean;
}

const initialState: ConfigState = {
  logo: null,
  theme: null,
  signalsLocked: true,
  insightsLocked: true,
  projectsLocked: true,
  valuesLocked: true,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLogo: (state, action: PayloadAction<string | null>) => {
      state.logo = action.payload;
    },
    setTheme: (state, action: PayloadAction<string | null>) => {
      state.theme = action.payload;
    },
    setFeatureLocks: (state, action: PayloadAction<any>) => {
      const { signals, insights, projects, values } = action.payload;
      state.signalsLocked = !signals;
      state.insightsLocked = !insights;
      state.projectsLocked = !projects;
      state.valuesLocked = !values;
    },
    clearConfig: (state) => {
      state.logo = null;
      state.theme = null;
      state.signalsLocked = true;
      state.insightsLocked = true;
      state.projectsLocked = true;
      state.valuesLocked = true;
    },
  },
});

export const { setLogo, setTheme, clearConfig, setFeatureLocks } = configSlice.actions;

export default configSlice.reducer;
