import React from "react";
import VistaXMLogo from "../../assets/images/VistaXM-logo-gray.svg";
import "../../styles/feature/Footer/Footer.scss";
import { literals } from "../../theme/literals";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Footer: React.FC = () => {
  const { token } = useSelector((state: RootState) => state?.auth);
  return (
    <div className="vista-container" style={{justifyContent: token? 'space-between': 'center'}}>
      <div className="vista-trademark">
        <span className="powered-text">{literals.poweredBy}</span>
        <img src={VistaXMLogo} alt="vista logo" className="img-container" />
      </div>
      {token && <div className="legal">
        <div>{literals.privacyPloicy}</div>
        <div>{literals.termsAndConditions}</div>
      </div>}
    </div>
  );
};

export default Footer;
