import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DigitalResponse,
  RelationalResponse,
  TransactionalResponse,
} from './SignalsQuery.type';
import { gql } from 'graphql-request';
import { graphqlBaseQuery } from '../../api/graphqlBaseQuery';

export const Signals = createApi({
  reducerPath: 'signals',
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getCsat: builder.query<any, void>({
      query: () => ({
        body: gql`
          query {
            signals {
              lastFourQuartersNps {
                year
                quarter
                score
              }
              lastFourQuartersCsat {
                tenantId
                year
                month
                score
              }
              customerHealthSummary {
                loyalCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                riskCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                percentageLoyal
                percentageRisk
                percentageNeutral
              }
              totalCustomers
            }
          }
        `,
      }),
      transformResponse: (response: any) => response,
    }),
    getTransactional: builder.query<TransactionalResponse, void>({
      query: () => ({
        body: gql`
          query Transactional {
            transactional {
              customerEffortScore {
                tenantId
                date
                year
                month
                quarter
                type
                value
              }
              csatBenchmark
              lastFourQuartersCsat {
                tenantId
                score
                month
                year
              }
              csatTouchPoints {
                tenantId
                touchpoint
                score
              }
            }
          }
        `,
      }),
      transformResponse: (response: TransactionalResponse) => response,
    }),
    getRelational: builder.query<RelationalResponse, void>({
      query: () => ({
        body: gql`
          query Relational {
            relational {
              npsBenchmark
              npsTrend {
                year
                quarter
                score
              }
              csatTrend {
                tenantId
                year
                month
                score
              }
              surveys {
                id
                title
                type
                customerSegment
                startDate
                endDate
                status
                numSent
                responsesReceived
              }
              lastQuarterSurveyStats {
                quarter
                year
                sent
                returned
                responseRate
              }
            }
          }
        `,
      }),
      transformResponse: (response: RelationalResponse) => response,
    }),
    getDigital: builder.query<DigitalResponse, void>({
      query: () => ({
        body: gql`
          query Digital {
            digital {
              averageCsat {
                date
                year
                month
                quarter
                type
                value
              }
              customerEffort  {
                date
                year
                month
                quarter
                type
                value
              }
              taskCompletionRate {
                date
                year
                month
                quarter
                type
                value
              }
              likelihoodToReturn {
                date
                year
                month
                quarter
                type
                value
              }
            }
          }
        `,
      }),
      transformResponse: (response: DigitalResponse) => response,
    }),
    getContent: builder.query<any, void>({
      query: () => ({
        body: gql`
          {
            content {
              contentEffectiveness {
                tenantId
                content
                score
                date
                mostCommonlySearched
                needsAttention
                comments
                year
                month
                quarter
              }
              mostCommonSearches {
                tenantId
                content
                score
                date
                mostCommonlySearched
                needsAttention
                comments
                year
                month
                quarter
              }
            }
          }         
        `,
      }),
      transformResponse: (response: any) => response,
    }),
  }),
});

export const {
  useGetCsatQuery,
  useGetTransactionalQuery,
  useGetRelationalQuery,
  useGetDigitalQuery,
  useGetContentQuery
} = Signals;
