import "../../../styles/page/ClientDetails/TenantDetails/DetractorTopicCell.scss";
import React, { useEffect, useRef, useState } from "react";
import PlusIcon from "../../../assets/images/PlusIcon.svg";
import CrossIcon from "../../../assets/images/cross.png";
import FilledDropDown from "../../../assets/images/FilledDropDown.svg";
import { Button, InputGroup, Form } from "react-bootstrap";
import "../../../sass/typography.scss";
import { literals } from "../../../theme/literals";
import Text from "../../../component/Text/Text";
import { colors } from "../../../theme/colors";
import {
  checkTopicExistance,
  filterTopicBySearchString,
} from "../utils/DetractorCellHelper";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../../store";
export const DetractorTopicCell = (params: any) => {
  const [displayedTopic, setDisplayedTopic] = useState(params.value || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [newTopic, setNewTopic] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const reducerAllTopics = useSelector(
    (state: RootState) => state.insight.uniqueTopic
  );
  const [allTopicsList, setAllTopicList] = useState(reducerAllTopics);
  const [availableTopicToSearch, setAvailableTopicToSearch] = useState(() =>
    allTopicsList.filter((topic: string) => !displayedTopic?.includes(topic))
  );
  const [filteredTopic, setFilterdTopics] = useState(availableTopicToSearch);
  useEffect(() => {
    const newAvailableTopic = reducerAllTopics.filter(
      (tag: string) => !displayedTopic.includes(tag)
    );
    setAvailableTopicToSearch(newAvailableTopic);
  }, [displayedTopic, reducerAllTopics]);
  useEffect(() => {
    setAllTopicList(reducerAllTopics);
  }, [reducerAllTopics]);
  useEffect(() => {
    setFilterdTopics(availableTopicToSearch);
  }, [availableTopicToSearch]);
  const handleTopicAddition = (topicToAdd: string) => {
    const isTopicExist = checkTopicExistance(displayedTopic, topicToAdd);
    if (isTopicExist) toast.error(literals.topicAlreadyExiste);
    else {
      const updatedTopics = [...displayedTopic, topicToAdd];
      saveDataToGrid(updatedTopics);
    }
  };

  const saveDataToGrid = (updatedTopics: string[]) => {
    setDisplayedTopic(updatedTopics);
    endEditMode();
    params.node.setDataValue(params.colDef.field, updatedTopics);
  };

  const endEditMode = () => {
    setNewTopic("");
    setInputVisible(false);
    setIsEditing(false);
    setDropdownVisible(false);
  };
  const handleSearch = (searchTerm: string) => {
    const searchedItems = filterTopicBySearchString(
      availableTopicToSearch,
      searchTerm
    );
    setFilterdTopics(searchedItems);
  };

  const handleTopicRemove = (removeItem: string) => {
    const updatedTopics = displayedTopic.filter(
      (item: string) => item !== removeItem
    );
    saveDataToGrid(updatedTopics);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const topicToAdd = newTopic.trim();
      if (topicToAdd) {
        handleTopicAddition(topicToAdd);
      } else {
        endEditMode();
      }
    } else if (e.key === "Escape") {
      endEditMode();
    }
  };

  const renderTopicItem = (item: string, index: number) => (
    <div key={`topic_${index}`} className="topic-content-wrapper">
      <span className="caption2">{item}</span>
      <img
        className="cross-icon"
        src={CrossIcon}
        alt="cross-icon"
        onClick={() => handleTopicRemove(item)}
      />
    </div>
  );

  const renderDropdown = () => (
    <div className="tags-search-container">
      <div className="tags-header">
        <Text
          text={literals.existingTag}
          color={colors.black}
          styleName="Heading6"
        />
        <img
          className="cross-icon"
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => setDropdownVisible(false)}
        />
      </div>
      <div className="tags-search-div">
        <InputGroup className="search-input" size="lg">
          <Form.Control
            aria-label="Large"
            value={searchTerm || ""}
            aria-describedby="inputGroup-sizing-sm"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <Button
            className="clear-search-button"
            disabled={!searchTerm}
            onClick={() => {
              setSearchTerm("");
              handleSearch("");
            }}
          >
            <img
              className="clear-search-icon"
              src={CrossIcon}
              alt="cross-icon"
            />
          </Button>
        </InputGroup>
        <Button
          className="search-button"
          onClick={() => handleSearch(searchTerm)}
        >
          {literals.search}
        </Button>
      </div>
      <div className="tags-content-container">
        {filteredTopic.length > 0 ? (
          filteredTopic.map((tag: any, index: any) => (
            <div
              key={`dropdown_${index}`}
              className="caption2 tags-content-topic"
              onClick={() => handleTopicAddition(tag)}
            >
              {tag}
            </div>
          ))
        ) : (
          <div className="dropdown-empty">No tags available</div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`topic-wrapper ${isEditing ? "editable" : ""}`}
      onDoubleClick={handleDoubleClick}
    >
      {isEditing ? (
        <>
          {displayedTopic.map(renderTopicItem)}
          {inputVisible && (
            <input
              ref={inputRef}
              type="text"
              className="add-topic-input"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          )}
          <div className="add-tag-wrapper">
            {
              <img
                className="plus-icon"
                src={PlusIcon}
                alt="plus-icon"
                onClick={() => {
                  setNewTopic("");
                  setInputVisible(true);
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
              />
            }
            <img
              className="dropdown-icon"
              src={FilledDropDown}
              alt="dropdown-icon"
              onClick={() => setDropdownVisible(!dropdownVisible)}
            />
            {dropdownVisible && renderDropdown()}
          </div>
        </>
      ) : (
        <>
          {displayedTopic.length === 0 ? (
            <span></span>
          ) : (
            displayedTopic.map(renderTopicItem)
          )}
        </>
      )}
    </div>
  );
};
