import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const SignalsCommonCrudQuery = createApi({
  reducerPath: "SignalsCommonCrudQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getSignalsCommon: builder.query({
      query: ({ tenantId, type }) => ({
        url: `/admin/signal-score/tenants/${tenantId}/type/${type}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response,
    }),
    addSignalsCommon: builder.mutation({
      query: (csatData) =>{
        return {
          url: `/admin/signal-score`,
          method: "POST",
          body: getBody(csatData),
        };
      },
    }),
    updateSignalsCommon: builder.mutation({
      query: (data) => ({
        url: `/admin/signal-score/${data.id}`,
        method: "PUT",
        body: getBody(data),
      }),
    }),
    removeSignalsCommon: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/signal-score/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSignalsCommonQuery,
  useLazyGetSignalsCommonQuery,
  useAddSignalsCommonMutation,
  useUpdateSignalsCommonMutation,
  useRemoveSignalsCommonMutation
} = SignalsCommonCrudQuery;


const getBody = (data: any) => {
  return data;
}