import lazyLoad from "./LazyLoad";
import ProtectedRoute from "./ProtectedRoute";
import { IRoute } from "./model";

const About = lazyLoad(() => import("../page/About/About"));
const CustomerSummary = lazyLoad(
  () => import("../page/CustomerSummary/CustomerSummary")
);
const Home = lazyLoad(() => import("../page/Home/Home"));
const Signals = lazyLoad(() => import("../page/Signals/Signals"));
const Ranking = lazyLoad(() => import("../page/Ranking/Ranking"));
const InsightAnalysis = lazyLoad(
  () => import("../page/InsightAnalysis/InsightAnalysis")
);
const Login = lazyLoad(() => import("../page/Login/Login"));
const CustomerDetails = lazyLoad(() => import("../page/Admin/CustomerDetails"));
const ClientDetails = lazyLoad(
  () => import("../page/ClientDetails/ClientDetails.tsx")
);
const TenantDashboard = lazyLoad(
  () => import("../page/Tenant/TenantDashboard.tsx")
);

export const getNavBar: IRoute[] = [
  {
    path: "/About",
    element: <ProtectedRoute element={<About />} />,
    name: "About",
  },
  {
    path: "/Tutorial",
    element: <ProtectedRoute element={<Home />} />,
    name: "Tutorial",
  },
  {
    path: "/Support",
    element: <ProtectedRoute element={<Home />} />,
    name: "Support",
  },
  {
    path: "/Task",
    element: <ProtectedRoute element={<Home />} />,
    name: "Task",
  },
  {
    path: "/Feedback",
    element: <ProtectedRoute element={<Home />} />,
    name: "Feedback",
  },
  {
    path: "/Profile",
    element: <ProtectedRoute element={<Home />} />,
    name: "Profile",
  },
];

export const getNavigation: IRoute[] = [
  {
    path: "/",
    element: <Login />,
    name: "Login",
  },
  {
    path: "/Home",
    element: <ProtectedRoute element={<Home />} />,
    name: "Home",
  },
  {
    path: "/Signals",
    element: <ProtectedRoute element={<Signals />} />,
    name: "Signals",
  },
  {
    path: "/Ranking",
    element: <ProtectedRoute element={<Ranking />} />,
    name: "Ranking",
  },
  {
    path: "/Insight-analysis",
    element: <ProtectedRoute element={<InsightAnalysis />} />,
    name: "Insight",
  },
  {
    path: "/CustomersSummary",
    element: <ProtectedRoute element={<CustomerSummary />} />,
    name: "CustomersSummary",
  },
  {
    path: "/CustomerDetails",
    element: <ProtectedRoute element={<CustomerDetails />} />,
    name: "CustomerDetails",
  },
  {
    path: "/ClientDetails",
    element: <ProtectedRoute element={<ClientDetails />} />,
    name: "ClientDetails",
  },
  {
    path: "/TenantDashboard",
    element: <ProtectedRoute element={<TenantDashboard />} />,
    name: "TenantDashboard",
  },
];

export const getRoutes = [...getNavigation, ...getNavBar];
