import { InsightsGridColumns } from "../../../constants/ClientDetails";
import { insightsGridField } from "../../ClientDetails/TenantDetails/gridConfig";
import {
  InsightTimeFrameEnum,
  InsightTimeFrameEnumValue,
  ProjectEstimationType,
} from "../ProjectInsights.type";
import { ColDef } from "ag-grid-community";

export const getPETableColumnDefs = (
  data: ProjectEstimationType[] | undefined,
  insightTitleCellRenderer: React.FC<any>,
  InsightTimeFrameCellRender: React.FC<any>
): ColDef<ProjectEstimationType>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: "Insight",
    field: insightsGridField.title,
    pinned: "left",
    headerClass: "first-column-header",
    cellClass: "sticky-column",
    cellRenderer: insightTitleCellRenderer,
    width: 400,
  });

  columnDefs.push({
    headerName: InsightsGridColumns.customerCentricScore,
    field: insightsGridField.customerCentricScore,
    width: 300,
  });

  columnDefs.push({
    headerName: InsightsGridColumns.orgCentricScore,
    field: insightsGridField.orgCentricScore,
    width: 200,
  });

  columnDefs.push({
    headerName: InsightsGridColumns.overallScore,
    field: insightsGridField.overallScore,
    width: 200,
  });
  columnDefs.push({
    headerName:
      InsightTimeFrameEnumValue[InsightTimeFrameEnum.ZERO_TO_THREE_MONTHS],
    field: InsightTimeFrameEnum.ZERO_TO_THREE_MONTHS,
    cellRenderer: InsightTimeFrameCellRender,
    width: 200,
  });
  columnDefs.push({
    headerName:
      InsightTimeFrameEnumValue[InsightTimeFrameEnum.FOUR_TO_SIX_MONTHS],
    field: InsightTimeFrameEnum.FOUR_TO_SIX_MONTHS,
    cellRenderer: InsightTimeFrameCellRender,
    width: 200,
  });
  columnDefs.push({
    headerName:
      InsightTimeFrameEnumValue[InsightTimeFrameEnum.SEVEN_TO_TWELVE_MONTHS],
    field: InsightTimeFrameEnum.SEVEN_TO_TWELVE_MONTHS,
    cellRenderer: InsightTimeFrameCellRender,
    width: 200,
  });
  columnDefs.push({
    headerName:
      InsightTimeFrameEnumValue[
        InsightTimeFrameEnum.GREATER_THAN_TWELVE_MONTHS
      ],
    field: InsightTimeFrameEnum.GREATER_THAN_TWELVE_MONTHS,
    cellRenderer: InsightTimeFrameCellRender,
    width: 200,
  });
  columnDefs.push({
    headerName: InsightsGridColumns.date,
    field: insightsGridField.date,
    width: 200,
  });
  return columnDefs;
};

export const getTimeFrameCheckValue = (timeFrameKey: InsightTimeFrameEnum) => {
  const flags = {
    ZERO_TO_THREE_MONTHS: false,
    FOUR_TO_SIX_MONTHS: false,
    SEVEN_TO_TWELVE_MONTHS: false,
    GREATER_THAN_TWELVE_MONTHS: false,
  };
  switch (timeFrameKey) {
    case InsightTimeFrameEnum.ZERO_TO_THREE_MONTHS:
      flags.ZERO_TO_THREE_MONTHS = true;
      break;
    case InsightTimeFrameEnum.FOUR_TO_SIX_MONTHS:
      flags.FOUR_TO_SIX_MONTHS = true;
      break;
    case InsightTimeFrameEnum.SEVEN_TO_TWELVE_MONTHS:
      flags.SEVEN_TO_TWELVE_MONTHS = true;
      break;
    case InsightTimeFrameEnum.GREATER_THAN_TWELVE_MONTHS:
      flags.GREATER_THAN_TWELVE_MONTHS = true;
      break;
    default:
      break;
  }
  return flags;
};
