import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { colors } from "../../theme/colors";

interface LayoutState {
  bgColor: string;
}

const initialState: LayoutState = {
  bgColor: colors.white,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setLayoutBgColor: (state, action: PayloadAction<string>) => {
      state.bgColor = action.payload;
    },
  },
});

export const { setLayoutBgColor } = layoutSlice.actions;

export default layoutSlice.reducer;
